import * as React from "react"
import {Helmet} from "react-helmet";


const SEO = (props) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{props.title}</title>
            <link rel="canonical" href={props.link} />
            <meta name="description" content={props.description} />
            <meta name="author" content="Paweł Czernecki" />
            <meta name="keywords" content="dzieci utracone, dzieci, utracone, miejsce pamięci, Dębica, pochówek, 15 październik, 25 marzec, zgłoszenie, dzień świętości życia, dzień dziecka utraconego, dzieci martwo urodzone, pogrzeb, Hospicjum Domowe, Parafia Miłosierdzia Bożego, formularz"/>
            <meta property="og:title" content={props.ogTitle}/>
            <meta property="og:url" content={props.link}/>
            <meta property="og:description" content={props.description}/>
            <meta property="og:image" content={'./../assets/ogimage.jpg'}/>
        </Helmet>
  )
}

export default SEO;
